<template>
  <div class="pb-2 sm:max-w-lg md:max-w-xl mx-auto">
    <form @submit.prevent="register" autocomplete="on">
      <fieldset>
        <input
          autocomplete="name"
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="name"
          type="text"
          name="name"
          placeholder="Name"
        />

        <input
          autocomplete="email"
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="email"
          type="email"
          name="email"
          placeholder="Email"
          required
        />
        <input
          v-if="commercial"
          autocomplete="business name"
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="businessName"
          type="text"
          name="businessName"
          placeholder="Business Name"
          required
        />
        <input
          autocomplete="phone"
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="phone"
          type="tel"
          name="phone"
          placeholder="Phone"
          required
        />
        <input
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="password"
          type="password"
          placeholder="Password"
          required
        />
        <input
          class="styled rounded w-full text-2xl text-gray-800 my-1"
          v-model="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          required
        />
        <div class="text-black my-1">
          <input type="checkbox" class="cursor-pointer" id="acceptTerms" v-model="acceptTerms" />
          <label for="acceptTerms"> I have read and agree to the </label
          ><router-link to="/privacy" target="_blank">terms of service</router-link>
        </div>

        <button
          type="submit"
          class="w-full btn-white text-2xl rounded-full md:rounded-lg my-5 h-16"
        >
          Sign Up
        </button>
      </fieldset>
    </form>
  </div>
</template>

<script>
export default {
  name: 'register',
  props: {
    commercial: { type: Boolean, default: false },
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      name: '',
      businessName: '',
      acceptTerms: false,
    };
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrder'];
    },
  },
  methods: {
    register: async function () {
      if (this.confirmPassword !== this.password) {
        this.$store.commit('setError', {
          message: 'Passwords do not match',
          color: 'red',
        });
        return;
      }
      if (!this.acceptTerms) {
        this.$store.commit('setError', {
          message: 'Please read and agree to the terms of service before continuing.',
          color: 'red',
          duration: 6500,
        });
        return;
      }

      const user = {
        email: this.email,
        phone: this.phone,
        password: this.password,
        name: this.name,
        businessName: this.businessName,
      };
      if (this.order) {
        user.shippingAddress = this.order.shippingAddress;
        user.shippingCity = this.order.shippingCity;
        user.shippingState = this.order.shippingState;
        user.shippingZip = this.order.shippingZip;
        user.phone = this.order.phone;
      }

      if (this.commercial) {
        user.permissionLevel = 'Commercial';
      } else {
        user.permissionLevel = 'Retail';
      }
      this.$store.dispatch('register', user).then(() => {
        if (user.permissionLevel === 'Retail') {
          this.$router.push('/user/settings');
        }
      });
    },
  },
  components: {},
};
</script>
