<template>
  <div
    class="relative"
    :class="page ? 'max-w-screen-lg mx-auto px-4 sm:px-10 md:px-16 lg:px-24' : ''"
  >
    <div
      class="absolute right-0 top-0 z-100 px-1"
      v-if="$route.name === 'User Orders'"
      @click="showAddOrder = !showAddOrder"
    >
      <font-awesome-icon
        icon="chevron-down"
        class="transition-transform duration-200"
        :class="showAddOrder ? 'flip' : null"
      />
    </div>
    <div
      v-if="$route.name === 'User Orders' && showAddOrder"
      class="w-full p-4 flex justify-between border-b"
    >
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Add Order to user</label>
        <div class="mt-1 flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              v-model="orderNumber"
              type="number"
              name="email"
              id="email"
              class="border focus:border-blue-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300"
              placeholder="1325"
            />
          </div>
          <button
            @click="addOrderToHistory()"
            type="button"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:border-blue-500"
          >
            <font-awesome-icon icon="plus" class="h-5 w-5 text-gray-400" />
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
    <form
      @submit.prevent="fetchData"
      class="flex justify-between items-center relative my-4 md:my-2 pt-2 pb-3 px-4 md:py-4 bg-white shadow rounded-md"
    >
      <h4>ORDER<span v-if="admin"> HISTORY</span> <span v-else>S</span></h4>
      <!-- Filter Orders -->
      <div class="md:flex items-center hidden" v-if="page">
        <label for="startDate" class="mt-2 md:mt-0 md:mx-4 w-52 font-medium"> Start: </label>
        <input
          type="date"
          class="ml-px md:ml-0 w-40 styled rounded shadow"
          id="startDate"
          v-model="startDate"
        />
      </div>
      <div class="md:flex items-center hidden" v-if="page">
        <label for="endDate" class="md:mx-4 w-52 font-medium"> End: </label>
        <input
          type="date"
          class="ml-2 md:ml-0 w-40 styled rounded shadow"
          id="endDate"
          v-model="endDate"
        />
      </div>
      <!-- Search -->
      <div class="flex ml-4" :class="page ? '' : 'w-full'">
        <button
          for="search"
          class="pb-2 sm:py-1 sm:top-1 cursor-pointer rounded-full w-8"
          :class="showSearch ? '-mr-10 z-1' : 'ml-auto'"
          @click="showSearchWrapper"
        >
          <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5 m-1" />
        </button>

        <input
          v-if="showSearch"
          @keyup.esc="(search = null), (showSearch = false)"
          type="text"
          id="searchField"
          ref="searchField"
          class="rounded-full w-full pl-10 py-1 sm:my-0 left-0 top-0 sm:top-1"
          placeholder="Search..."
          v-model="search"
        />
      </div>
      <button
        type="button"
        v-if="showSearch"
        @click="(search = null), (showSearch = false)"
        class="my-2 sm:my-0 top-0 sm:top-1 px-2 py-1 hover:bg-red text-xl rounded"
      >
        <font-awesome-icon icon="times" />
      </button>
    </form>
    <!-- End Search -->

    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="setSort('orderNumber')"
                >
                  Number
                  <font-awesome-icon
                    icon="chevron-down"
                    id="orderNumber"
                    class="chevron transition-transform duration-200"
                  />
                </th>
                <th
                  scope="col"
                  class="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="setSort('jobName')"
                >
                  Job Name
                  <font-awesome-icon
                    icon="chevron-down"
                    id="jobName"
                    class="chevron transition-transform duration-200"
                  />
                </th>
                <th
                  scope="col"
                  class="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="setSort('date')"
                >
                  Date
                  <font-awesome-icon
                    icon="chevron-down"
                    id="date"
                    class="chevron transition-transform duration-200 flip"
                  />
                </th>
                <th
                  scope="col"
                  class="hidden md:block px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="setSort('deliveryDate')"
                  v-if="page"
                >
                  Delivery
                  <font-awesome-icon
                    icon="chevron-down"
                    id="deliveryDate"
                    class="chevron transition-transform duration-200"
                  />
                </th>
                <th
                  scope="col"
                  class="px-2 md:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  @click="setSort('status')"
                >
                  Status
                  <font-awesome-icon
                    icon="chevron-down"
                    id="status"
                    class="chevron transition-transform duration-200"
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="orders.length > 0">
              <tr
                v-for="(order, orderIdx) in orders"
                :key="order._id"
                :class="orderIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td class="px-2 md:px-3 py-4 whitespace-nowrap text-sm font-medium">
                  <div class="text-sm text-gray-900">
                    #{{ order.orderNumber }}
                    <span v-if="order.overdue" class="text-xs text-red">(overdue)</span>
                    <span v-else-if="!order.paid" class="text-xs text-gray-600">(unpaid)</span>
                  </div>
                  <div class="text-xs sm:text-sm text-gray-500" v-if="order.purchaseOrder">
                    ({{ order.purchaseOrder }})
                  </div>
                </td>
                <td class="px-2 md:px-3 py-4 whitespace-nowrap text-sm font-medium">
                  <div
                    class="text-sm text-gray-900"
                    v-if="order.orderName && order.orderName != 'Cart'"
                  >
                    {{ order.orderName }}
                  </div>
                </td>
                <td class="px-2 md:px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="hidden sm:block">
                    {{ order.date ? order.date.slice(0, 10) : null }}
                  </span>
                  <span class="sm:hidden">
                    {{ order.date ? order.date.slice(5, 10) : null }}
                  </span>
                </td>
                <td
                  class="px-2 md:px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden md:block"
                  v-if="page"
                >
                  {{ order.deliveryDate ? order.deliveryDate.slice(0, 10) : null }}
                </td>
                <td class="px-2 md:px-3 py-4 whitespace-nowrap text-sm" :class="getColor(order)">
                  {{ order.status }}
                </td>
                <td class="px-2 md:px-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button @click="handleNavigation(order._id)" class="text-action">View</button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4" class="text-center py-4 uppercase bg-white">
                  <div>No orders</div>
                  <button
                    v-if="search"
                    @click="(search = null), fetchData()"
                    class="btn-action rounded mt-2"
                  >
                    Clear Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Pagination
      pageType="Order"
      :admin="admin"
      :totalAvailable="totalAvailable"
      :totalRevenue="total"
      :limit="limit"
      :selectableLimits="selectableLimits"
      :currentPage="currentPage"
      @setCurrentPage="setCurrentPage"
      @setLimit="setLimit"
    />
  </div>
</template>

<script>
import { status as getColor } from '@/assets/functions/getColor.js';
import Pagination from '@/components/shared/Pagination';

export default {
  data: function () {
    return {
      search: null,
      showSearch: false,

      startDate: null,
      endDate: null,
      startDeliveryDate: null,
      endDeliveryDate: null,

      currentPage: 1,
      limit: 25,
      query: '',
      sort: '-date',
      selectableLimits: [50, 100, 200],

      orderNumber: null,
      showAddOrder: false,
    };
  },
  props: {
    userName: { type: String, default: null },
    page: { type: Boolean, default: true },
    admin: { type: Boolean, default: false },
    commercial: { type: Boolean, default: false },
    user: { type: Object },
  },
  computed: {
    orders() {
      if (this.commercial) {
        //this will return the orders for a logged in commercial user and an admin viewing a commercial account
        return this.$store.getters['company/getOrders'];
      } else if (this.admin) {
        //this will return all non-commercial account's orders on the admin page
        return this.$store.getters['users/getUserOrders'];
      } else {
        //return the user's orders
        return this.$store.getters['user/getMyOrders'];
      }
    },
    getPadding() {
      if (this.$route.name === 'Profile' || this.$route.name === 'Create Order')
        return 'py-2 px-3 flex justify-between items-center pr-10';
      else return 'py-3 px-8 grid grid-cols-6 pr-6';
    },
    totalAvailable() {
      return this.$store.getters['company/getTotalNumberOfOrders'];
    },
    total() {
      let total = 0;
      this.orders.forEach((el) => {
        total += el.total;
      });
      if (total) {
        total = total.toFixed(2);
      }
      return total;
    },
  },
  watch: {
    startDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    endDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    startDeliveryDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    endDeliveryDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    limit() {
      this.currentPage = 1;
      this.fetchData();
    },
    sort() {
      this.currentPage = 1;
      this.fetchData();
    },
  },
  methods: {
    addOrderToHistory() {
      if (this.orderNumber)
        this.$store.dispatch('users/addOrderToHistory', {
          orderNumber: this.orderNumber,
          user_id: this.user._id,
        });
    },
    showSearchWrapper() {
      this.showSearch = !this.showSearch;

      this.$nextTick(() => {
        if (this.$refs.searchField) this.$refs.searchField.focus();
      });
    },

    handleNavigation(id) {
      if (this.admin) {
        const routeData = this.$router.resolve({
          name: 'Order Editor',
          params: { id: id },
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(`/user/orders/${id}`);
      }
    },
    getColor: getColor,
    getBackgroundColor(index) {
      if (index % 2 !== 0) return 'bg-white';
      else return null;
    },
    buildQuery() {
      this.query = '';
      if (this.admin) {
        this.query += `user_id=${this.user._id}`;
      }
      if (this.startDate) {
        const d = new Date(this.startDate);
        this.query +=
          '&date[gte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.endDate) {
        const d = new Date(this.endDate);
        this.query +=
          '&date[lte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.startDeliveryDate) {
        const d = new Date(this.startDeliveryDate);
        this.query +=
          '&deliveryDate[gte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.endDeliveryDate) {
        const d = new Date(this.endDeliveryDate);

        this.query +=
          '&deliveryDate[lte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.search) {
        this.query += `&search=${encodeURIComponent(this.search.replace(/^#/, ''))}`;
      }

      this.query += '&page=' + this.currentPage;
      this.ending = `&sort=${this.sort}&limit=${this.limit}`;
    },
    fetchData() {
      this.buildQuery();
      const query = `${this.query}${this.ending}`;
      console.log(query);
      if (this.commercial || this.admin) {
        this.$store.dispatch('company/getOrders', query);
      }
    },
    setSort(selection) {
      //return ;
      const chevrons = document.getElementsByClassName('chevron');
      chevrons.forEach((el) => {
        if (el.classList.contains('flip')) {
          el.classList.remove('flip');
        }
      });
      const selected = document.getElementById(selection);
      if (selection !== this.sort) {
        selected.classList.add('flip');
      }
      if (this.sort === selection) {
        this.sort = `-${selection}`;
      } else {
        this.sort = selection;
      }
    },
    setLimit(payload) {
      this.currentPage = 1;
      this.limit = payload;
      this.fetchData();
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  components: {
    Pagination,
  },
};
</script>
<style scoped>
.flip {
  transform: rotate(180deg);
}
</style>
